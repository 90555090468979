import type { Widget } from '../../types/Widget';
import { WidgetId } from '../../types/Widget';
import isMostPopularWidget from '../../utils/isMostPopularWidget';
import type Localiser from '../Localiser';
import type { Site } from '../sites/types/Site';
import widgetMap from '../widgetConfigs';

import BaseFeatures, {
  ArticleCategory,
  ArticleType,
  Platform,
  SiteId,
  TabCategory,
} from './BaseFeatures/BaseFeatures';
import FeatureId from './types/FeatureId';
import FeatureType from './types/FeatureType';
import FeatureValue from './types/FeatureValue';

export default class extends BaseFeatures {
  constructor(
    attributes: Record<string, string | undefined>,
    widget: Widget,
    editorial: boolean,
    platform: string,
    localiser: Localiser,
    site: Site,
    territory: string,
    keywords: string[] | null,
  ) {
    super(attributes, widget, editorial, platform, localiser, site, territory, keywords);

    this.features = [
      {
        id: FeatureId.CONTRACTS_ONLY,
        type: FeatureType.NUMBER,
        value: FeatureValue.CONTRACTS_ONLY,
      },
      {
        id: FeatureId.DEALS_ONLY,
        type: FeatureType.NUMBER,
        value: FeatureValue.DEALS_ONLY,
      },
      {
        id: FeatureId.DISPLAY_TYPE,
        type: FeatureType.TEXT,
        value: FeatureValue.DISPLAY_TYPE,
      },
      {
        id: FeatureId.WIDGET_INTRODUCTION,
        type: FeatureType.TEXT,
        value: FeatureValue.WIDGET_INTRODUCTION,
      },
      {
        id: FeatureId.SHOW_FALLBACK,
        type: FeatureType.TEXT,
        value: FeatureValue.SHOW_FALLBACK,
      },
      {
        id: FeatureId.LINK,
        type: FeatureType.TEXT,
        value: FeatureValue.LINK,
      },
      {
        id: FeatureId.SHOW_NO_DEALS,
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_NO_DEALS,
        getDefaultValue: (): boolean => {
          const { widget } = this;

          switch (widget.id) {
            case 'nighthawk':
              return true;
            default:
              return false;
          }
        },
      },
      {
        id: FeatureId.LINK_TEXT,
        type: FeatureType.TEXT,
        value: FeatureValue.LINK_TEXT,
      },
      {
        id: FeatureId.IMAGE,
        type: FeatureType.TEXT,
        value: FeatureValue.IMAGE,
      },
      {
        id: FeatureId.WEBP_IMAGE,
        type: FeatureType.TEXT,
        value: FeatureValue.WEBP_IMAGE,
      },
      {
        id: FeatureId.NAME,
        type: FeatureType.TEXT,
        value: FeatureValue.NAME,
      },
      {
        id: FeatureId.SKIP,
        type: FeatureType.LIST,
        value: FeatureValue.SKIP,
        options: [
          {
            value: '',
            formatted_value: 'Always render',
          },
          {
            value: 'editorial',
            formatted_value: 'Skip if there is an Editorial widget on the page',
          },
          {
            value: 'phones',
            formatted_value: 'Skip if the article category is phones',
          },
          {
            value: 'retail',
            formatted_value: 'Skip if the article category is retail',
          },
          {
            value: 'dealsy',
            formatted_value: 'Skip if the article is black friday, prime day or a deals article',
          },
          {
            value: 'disabled',
            formatted_value:
              'Skip if the widget is disabled (determined by checking for van-hawk-none in the hawk client)',
          },
        ],
        getDefaultValue: (): null => null,
      },
      {
        id: FeatureId.ORIGIN,
        type: FeatureType.TEXT,
        value: FeatureValue.ORIGIN,
      },
      {
        id: FeatureId.STAR,
        type: FeatureType.TEXT,
        value: FeatureValue.STAR,
      },
      {
        id: FeatureId.STAR_LABEL,
        type: FeatureType.TEXT,
        value: FeatureValue.STAR_LABEL,
      },
      {
        id: FeatureId.IS_PREFERRED_PARTNER,
        type: FeatureType.TEXT,
        value: FeatureValue.IS_PREFERRED_PARTNER,
      },
      {
        id: FeatureId.SHOW_DEAL_WIDGET_FOOTER,
        label: 'Deal Widget Footer',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_DEAL_WIDGET_FOOTER,
        battle: true,
        getDefaultValue: (): boolean => false,
      },
      {
        id: FeatureId.EXCLUDE_FROM,
        type: FeatureType.TEXT,
        value: FeatureValue.EXCLUDE_FROM,
      },
      {
        id: FeatureId.WIDGET_TYPE,
        label: 'Widget Type',
        type: FeatureType.LIST,
        value: FeatureValue.WIDGET_TYPE,
        options: widgetMap?.map((w) => {
          return {
            value: w?.id,
            formatted_value: w?.label,
          };
        }),
        getDefaultValue: (): string => {
          const { widget } = this;
          return widget?.id;
        },
        battle: true,
      },
      {
        id: FeatureId.SHOW_LINK,
        label: 'All Deals Link',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_LINK,
        battle: true,
      },
      {
        id: FeatureId.HIDDEN_DEAL_WIDGET,
        label: 'Hidden Deal Widget',
        type: FeatureType.BOOL,
        value: FeatureValue.HIDDEN_DEAL_WIDGET,
        battle: true,
        getDefaultValue: (): boolean => true,
      },
      {
        id: FeatureId.SHOW_TOGGLE,
        label: 'Widget Toggle',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_TOGGLE,
        battle: true,
        getDefaultValue: (): boolean => false,
      },
      {
        id: FeatureId.SWITCH_MERCHANTLINK_TO_REVIEW,
        label: 'Switch Merchantlink To Review widget',
        type: FeatureType.BOOL,
        value: FeatureValue.SWITCH_MERCHANTLINK_TO_REVIEW,
        battle: true,
        getDefaultValue: (): boolean => false,
      },
      {
        id: FeatureId.SHOW_FILTERS,
        label: 'Show Filters',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_FILTERS,
        battle: true,
        getDefaultValue: (): boolean => {
          const { widget } = this;

          switch (widget?.id) {
            case WidgetId.BROADBAND:
            case WidgetId.COMPARISON:
            case WidgetId.LABELS:
            case WidgetId.POCKET:
            case WidgetId.SHOP:
              return true;
            default:
              return false;
          }
        },
      },
      {
        id: FeatureId.SHOW_ACTIVE_FILTERS,
        label: 'Show Active Filters',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_ACTIVE_FILTERS,
        battle: true,
        getDefaultValue: (): boolean => {
          const { widget, site } = this;

          if (widget?.id === WidgetId.SHOP && site?.id === SiteId.MARIE_CLAIREUS) {
            return false;
          }
          return true;
        },
      },
      {
        id: FeatureId.BLACKLISTED_FILTERS,
        label: 'Blacklisted Filters',
        type: FeatureType.TEXT,
        value: FeatureValue.BLACKLISTED_FILTERS,
        battle: true,
        getDefaultValue: (): string => {
          const { widget, site } = this;

          if (widget?.id === WidgetId.SHOP && site?.id === SiteId.MARIE_CLAIREUS) {
            return 'Gender,Condition';
          }
          return '';
        },
      },
      {
        id: FeatureId.SHOW_FILTER_COUNTS,
        label: 'Show Filter Counts',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_FILTER_COUNTS,
        battle: true,
        getDefaultValue: (): boolean => {
          return false;
        },
      },
      {
        id: FeatureId.GAME_PLATFORM_TABS,
        label: 'Game Platform Tabs',
        type: FeatureType.BOOL,
        value: FeatureValue.GAME_PLATFORM_TABS,
        getDefaultValue: (): boolean => false,
      },
      {
        id: FeatureId.TABS,
        label: 'Tabs',
        type: FeatureType.LIST,
        value: FeatureValue.TABS,
        battle: true,
        options: [
          {
            value: 'simOnly',
            formatted_value: 'Sim Only Tab',
          },
          {
            value: 'priority',
            formatted_value: 'Priority Label Tabs',
          },
          {
            value: 'network',
            formatted_value: 'Network/Provider Tabs',
          },
          {
            value: 'requestedModel',
            formatted_value: 'Requested Model Tabs',
          },
        ],
        getDefaultValue: (): string | null => {
          const { attributes, widget } = this;
          const defaultValue = ((): string | null => {
            switch (widget?.id) {
              case WidgetId.COMPARISON:
                return 'simOnly';
              case WidgetId.SPECS:
                return 'requestedModel';
              case WidgetId.TABS:
                return 'priority';
              default:
                return null;
            }
          })();

          if (attributes?.gamePlatformTabs) {
            return 'gamePlatform';
          }

          return defaultValue;
        },
      },
      {
        id: FeatureId.SHOW_BUNDLE_OFFERS,
        label: 'Show Bundle Offers',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_BUNDLE_OFFERS,
        battle: true,
        getDefaultValue: (): boolean => {
          const { widget } = this;

          switch (widget?.id) {
            case WidgetId.BUNDLE:
              return true;
            default:
              return false;
          }
        },
      },
      {
        id: FeatureId.SEASONAL,
        label: 'Seasonal',
        type: FeatureType.LIST,
        value: FeatureValue.SEASONAL,
        options: [
          {
            value: 'event',
            formatted_value: 'Show Seasonal Event',
          },
          {
            value: 'noEvent',
            formatted_value: "Don't Show Event",
          },
        ],
        battle: true,
        getDefaultValue: (): string => 'event',
      },
      {
        id: FeatureId.SHOW_HOT_DEAL,
        label: 'Hot Deal Flags',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_HOT_DEAL,
        battle: true,
        getDefaultValue: (): boolean => {
          const { widget } = this;
          return [WidgetId.PRICE, WidgetId.REVIEW, WidgetId.MERCHANT_LINK].includes(widget?.id);
        },
      },
      {
        id: FeatureId.AVAILABLE_FROM,
        label: 'Available From',
        type: FeatureType.BOOL,
        value: FeatureValue.AVAILABLE_FROM,
        battle: true,
        getDefaultValue: (): boolean => false,
      },
      {
        id: FeatureId.SHOW_PRICE,
        label: 'Show Price',
        type: FeatureType.LIST,
        value: FeatureValue.SHOW_PRICE,
        options: [
          {
            value: 'showPrice',
            formatted_value: 'Show Price',
          },
          {
            value: 'noPrice',
            formatted_value: "Don't Show Price",
          },
          {
            value: 'availableFrom',
            formatted_value: "Don't show Prices and show Available from",
          },
          {
            value: 'stream',
            formatted_value: 'Show Stream on and show price',
          },
          {
            value: 'availableToStream',
            formatted_value: "Show Available to and don't show Prices",
          },
          {
            value: 'watchAt',
            formatted_value: "Show Watch At and don't show Prices",
          },
        ],
        battle: true,
        getDefaultValue: (): string => {
          const { defaultTab, widget, site, platform, attributes } = this;
          // No price on Vanilla buying guides for retail price widgets
          // (except Whathifi & MarieclaireUS & Goodto)
          const platformTypeArray: string[] = [Platform.RESPONSIVE, Platform.PROGRESSIVE];
          const sideIdArray: string[] = [SiteId.WHATHIFI, SiteId.MARIE_CLAIREUS, SiteId.GOODTO];
          if (
            platformTypeArray.includes(platform) &&
            !sideIdArray.includes(site.id) &&
            attributes?.articleType === ArticleType.BUYING_GUIDE &&
            defaultTab?.category === ArticleCategory.RETAIL &&
            (widget?.id === WidgetId.PRICE || widget?.id === WidgetId.MERCHANT_LINK)
          ) {
            return 'noPrice';
            // No price on Mona buying guides for retail price, merchantlink & monawidget types
          }
          if (
            (platform === Platform.MONA ||
              platform === Platform.MOZO ||
              platform === Platform.SHORTLIST) &&
            attributes?.articleType === ArticleType.BUYING_GUIDE &&
            defaultTab?.category === ArticleCategory.RETAIL &&
            [WidgetId.PRICE, WidgetId.MONA_WIDGET, WidgetId.MERCHANT_LINK].includes(widget?.id)
          ) {
            return 'noPrice';
          }
          if (
            defaultTab?.category === TabCategory.MULTI_MEDIA &&
            widget?.id === WidgetId.MERCHANT_LINK
          ) {
            return 'stream';
          }
          if (widget?.id === WidgetId.STREAMING) {
            return 'watchAt';
          }

          return 'showPrice';
        },
      },
      {
        id: FeatureId.SHOW_WAS_PRICE,
        label: 'Was Price',
        type: FeatureType.LIST,
        value: FeatureValue.SHOW_WAS_PRICE,
        options: [
          {
            value: 'price',
            formatted_value: 'Only Show Price',
          },
          {
            value: 'promoPercentage',
            formatted_value: 'Only Show Percentage Discount Promo',
          },
          {
            value: 'allPercentage',
            formatted_value: 'Show Price & Percentage Discount Promo',
          },
          {
            value: 'promoSaving',
            formatted_value: 'Only Show Monetary Discount Promo',
          },
          {
            value: 'allSaving',
            formatted_value: 'Show Price & Monetary Discount Promo',
          },
          {
            value: 'percentagePrice',
            formatted_value: 'Show Price Percentage Under Price',
          },
        ],
        battle: true,
        getDefaultValue: (): string => {
          const { widget } = this;
          switch (widget?.id) {
            case WidgetId.MULTI_MODEL_REVIEW:
            case WidgetId.REVIEW:
            case WidgetId.SHOP:
            case WidgetId.COMPARISON:
            case WidgetId.LABELS:
            case WidgetId.BUNDLE:
            case WidgetId.PRODUCT_NAME:
            case WidgetId.TABS:
            case WidgetId.PARTNER:
              return 'price';
            case WidgetId.PROMOTION:
              return 'promoPercentage';
            default:
              return '';
          }
        },
      },
      {
        id: FeatureId.PERCENTAGE_OFF_THRESHOLD,
        label: 'Percentage Off Threshold',
        type: FeatureType.NUMBER,
        value: FeatureValue.PERCENTAGE_OFF_THRESHOLD,
        battle: true,
        getDefaultValue: (): number => 10,
      },
      {
        id: FeatureId.SHOW_PRICE_ROUNDED,
        label: 'Round Price',
        type: FeatureType.LIST,
        value: FeatureValue.SHOW_PRICE_ROUNDED,
        battle: true,
        getDefaultValue: (): string => 'no',
        options: [
          {
            value: '',
            formatted_value: 'Select if you want to round prices',
          },
          {
            value: 'yes',
            formatted_value: 'Show price rounded',
          },
          {
            value: 'no',
            formatted_value: 'Show price not rounded',
          },
        ],
      },
      {
        id: FeatureId.SHOW_REVIEWS,
        label: 'Star Ratings',
        type: FeatureType.LIST,
        value: FeatureValue.SHOW_REVIEWS,
        options: [
          {
            value: '',
            formatted_value: 'Select Star Ratings',
          },
          {
            value: 'all',
            formatted_value: 'Show All Star Ratings',
          },
          {
            value: 'priority',
            formatted_value: 'Show Priority Star Ratings',
          },
          {
            value: 'reversePriority',
            formatted_value: 'Show Reverse Priority Star Ratings',
          },
          {
            value: 'asin',
            formatted_value: 'Show Amazon Star Rating',
          },
          {
            value: 'walmart',
            formatted_value: 'Show Walmart Star Rating',
          },
          {
            value: 'streamingScores',
            formatted_value: 'Show IMDB and Rotten Tomatoes Sscores',
          },
          {
            value: 'none',
            formatted_value: 'Do not Show Any Star Rating',
          },
        ],
        battle: true,
        getDefaultValue: (): string => {
          const { widget, defaultTab } = this;

          switch (widget.id) {
            case WidgetId.BUNDLE:
              return 'none';
            case WidgetId.STREAMING:
            case WidgetId.MERCHANT_LINK:
              return 'streamingScores';
            default:
              if (defaultTab?.category === 'multimedia') {
                return 'streamingScores';
              }
              return 'priority';
          }
        },
      },
      {
        id: FeatureId.SHOW_REVIEWS_POSITION,
        label: 'Star Ratings Position',
        type: FeatureType.LIST,
        value: FeatureValue.SHOW_REVIEWS_POSITION,
        options: [
          {
            value: '',
            formatted_value: 'Select Star Ratings',
          },
          {
            value: 'default',
            formatted_value: 'Show Star Ratings in Default Position',
          },
          {
            value: 'top',
            formatted_value: 'Show Star Ratings at the Top',
          },
          {
            value: 'bottom',
            formatted_value: 'Show Star Ratings at the Bottom',
          },
        ],
        battle: true,
        getDefaultValue: (): string => 'default',
      },
      {
        id: FeatureId.WIDGET_TITLE,
        label: 'Widget Title',
        type: FeatureType.TEXT,
        value: FeatureValue.WIDGET_TITLE,
        battle: true,
        getDefaultValue: (): string => {
          const { attributes, widget, editorial, localiser, site, platform } = this;

          if (widget && site) {
            // Try to translate the widget title for dynamic widgets, as
            // we don't have the translation in the hawk client
            if (attributes?.widgetTitle) {
              return localiser?.translate(attributes?.widgetTitle);
            }
            const isPriceWidget = [WidgetId.PRICE].indexOf(widget?.id) >= 0;

            // Don't add a title for a price or tableprice widget
            if (
              editorial &&
              !isPriceWidget &&
              widget?.id !== WidgetId.DEAL &&
              widget?.id !== WidgetId.STICKY &&
              widget?.id !== WidgetId.SHOP &&
              widget?.id !== WidgetId.PARTNER
            ) {
              const modelNames = ((): string => {
                // Add a space after any comma without a space after it
                const models = (attributes?.modelName || '').replace(/,\b/g, ', ');

                // Generate multi model title - replace final comma with 'and'
                if (models?.split(',').length > 1) {
                  const lastComma = models?.lastIndexOf(',');
                  return `${models?.substring(0, lastComma)} ${localiser?.translate(
                    'sentenceJoin',
                  )} ${models?.substring(lastComma + 1)}`;
                }
                return models;
              })();

              if (widget?.id === WidgetId.MERCHANT_LINK || widget?.id === WidgetId.STREAMING) {
                return '';
              }

              return localiser?.translate('defaultWidgetTitle', [modelNames]);
            }
            if (
              widget?.id === WidgetId.MERCHANT_LINK &&
              platform === Platform.RESPONSIVE &&
              attributes?.articleType === ArticleType.REVIEW
            ) {
              return localiser?.translate('fullPriceWidgetTitle').toUpperCase();
            }
            if (attributes?.articleType === ArticleType.AWARDS && widget?.id === WidgetId.REVIEW) {
              return localiser.translate('fullPriceWidgetTitle');
            }
          }
          return '';
        },
      },
      {
        id: FeatureId.SHOW_WIDGET_TITLE,
        label: 'Widget Title',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_WIDGET_TITLE,
        battle: true,
      },
      {
        id: FeatureId.LOAD_MORE,
        label: 'Load More',
        type: FeatureType.BOOL,
        value: FeatureValue.LOAD_MORE,
        battle: true,
        getDefaultValue: (): boolean => {
          const { widget } = this;

          switch (widget?.id) {
            case WidgetId.PRICE:
              return false;
            default:
              return true;
          }
        },
      },
      {
        id: FeatureId.SHOW_SHIPPING,
        label: 'Shipping',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_SHIPPING,
        battle: true,
        getDefaultValue: (): boolean => {
          const { platform } = this;

          switch (platform) {
            case Platform.PURCH:
              return false;
            default:
              return true;
          }
        },
      },
      {
        id: FeatureId.SHOW_SHIPPING_POSITION,
        label: 'Prime Logo Position',
        type: FeatureType.LIST,
        value: FeatureValue.SHOW_SHIPPING_POSITION,
        battle: true,
        options: [
          {
            value: '',
            formatted_value: 'Select Prime Logo Position',
          },
          {
            value: 'top',
            formatted_value: 'Show Prime Logo in Promo Flag',
          },
          {
            value: 'inside',
            formatted_value: 'Show Prime Logo inside the Button',
          },
          {
            value: 'next',
            formatted_value: 'Show Prime Logo next the Button',
          },
        ],
        getDefaultValue: (): string => {
          const { attributes, platform } = this;

          if (
            platform === Platform.RESPONSIVE &&
            attributes?.articleType === ArticleType.BUYING_GUIDE &&
            attributes?.articleCategory === ArticleCategory.RETAIL
          ) {
            return 'top';
          }

          return '';
        },
      },
      {
        id: FeatureId.SHOW_PROMOS,
        label: 'Promos',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_PROMOS,
        battle: true,
        getDefaultValue: (): boolean => {
          const { platform, widget, site } = this;

          if (widget?.id === WidgetId.STICKY || widget?.id === WidgetId.PARTNER) {
            return false;
          }
          if (platform === Platform.PURCH) {
            return false;
          }
          if (widget?.id === WidgetId.SHOP && site?.id === SiteId.MARIE_CLAIREUS) {
            return false;
          }

          return true;
        },
      },
      {
        id: FeatureId.PROMOS_POSITION,
        label: 'Promos Position',
        type: FeatureType.LIST,
        value: FeatureValue.PROMOS_POSITION,
        battle: true,
        options: [
          {
            value: '',
            formatted_value: 'Select Promos Position',
          },
          {
            value: 'above',
            formatted_value: 'Show Promos at the Top',
          },
          {
            value: 'below',
            formatted_value: 'Show Promos at the Bottom',
          },
          {
            value: 'hidden',
            formatted_value: "Don't show Promos",
          },
        ],
        getDefaultValue: (): string => {
          const { attributes, platform, widget } = this;

          if (
            widget?.id === WidgetId.VULTURE ||
            widget?.id === WidgetId.MOST_POPULAR_OFFERS ||
            widget?.id === WidgetId.TILE
          ) {
            return 'hidden';
          }

          if (
            platform === Platform.RESPONSIVE &&
            attributes?.articleType === ArticleType.REVIEW &&
            attributes?.articleCategory === ArticleCategory.RETAIL
          ) {
            return 'below';
          }

          return 'above';
        },
      },
      {
        id: FeatureId.CUSTOM_PROMO,
        type: FeatureType.TEXT,
        value: FeatureValue.CUSTOM_PROMO,
      },
      {
        id: FeatureId.CUSTOM_PROMOS,
        type: FeatureType.TEXT,
        value: FeatureValue.CUSTOM_PROMOS,
      },
      {
        id: FeatureId.COUNTDOWN_POSITION,
        label: 'Countdown Position',
        type: FeatureType.LIST,
        value: FeatureValue.COUNTDOWN_POSITION,
        battle: true,
        options: [
          {
            value: '',
            formatted_value: 'Select Countdown Position',
          },
          {
            value: 'above',
            formatted_value: 'Show Countdown at the Top',
          },
          {
            value: 'below',
            formatted_value: 'Show Countdown at the Bottom',
          },
          {
            value: 'hidden',
            formatted_value: "Don't show Countdown",
          },
        ],
        getDefaultValue: (): string => {
          const { widget } = this;

          if (
            widget?.id === WidgetId.VULTURE ||
            widget?.id === WidgetId.MOST_POPULAR_OFFERS ||
            widget?.id === WidgetId.TILE ||
            widget?.id === WidgetId.BROADBAND
          ) {
            return 'hidden';
          }

          return 'below';
        },
      },
      {
        id: FeatureId.DISPLAY_BUNDLE_ROW,
        label: 'Display Bundle Row',
        type: FeatureType.BOOL,
        value: FeatureValue.DISPLAY_BUNDLEROW,
        battle: true,
        getDefaultValue: (): boolean => true,
      },
      {
        id: FeatureId.DISPLAY_NAME_TYPE,
        label: 'Type of Display Name',
        type: FeatureType.LIST,
        value: FeatureValue.DISPLAY_NAME_TYPE,
        battle: true,
        options: [
          {
            value: '',
            formatted_value: 'Select Type of Display Name',
          },
          {
            value: 'fullName',
            formatted_value: 'Model name with Labels',
          },
          {
            value: 'product',
            formatted_value: 'Product name',
          },
          {
            value: 'model',
            formatted_value: 'Model name',
          },
          {
            value: 'modelWithBrand',
            formatted_value: 'Model name with Brand',
          },
          {
            value: 'contractLength',
            formatted_value: 'Contract length',
          },
          {
            value: 'brandMerchant',
            formatted_value: 'Model name with Brand or the Merchant Name',
          },
        ],
        getDefaultValue: (): string => {
          const { widget } = this;

          switch (widget?.id) {
            case WidgetId.BESTOF:
            case WidgetId.COMPARISON:
            case WidgetId.MULTI_MODEL_REVIEW:
            case WidgetId.PARTNER:
              return 'modelWithBrand';
            case WidgetId.DOTW:
            case WidgetId.MAGAZINE_SUBSCRIPTIONS:
            case WidgetId.PEACOCK:
            case WidgetId.PRICE:
            case WidgetId.PRODUCT_NAME:
            case WidgetId.PROMOTION:
            case WidgetId.SIMPLE:
              return 'product';
            case WidgetId.POCKET:
            case WidgetId.REVIEW:
            case WidgetId.BROADBAND:
              return 'fullName';
            case WidgetId.SHOP:
              return 'model';
            default:
              return '';
          }
        },
      },
      {
        id: FeatureId.DISPLAY_NAME_EXPANDABLE,
        label: 'Ellipsis Display Name',
        type: FeatureType.BOOL,
        value: FeatureValue.DISPLAY_NAME_EXPANDABLE,
        battle: true,
        getDefaultValue: (): boolean => {
          const { attributes, platform } = this;
          return isMostPopularWidget(attributes?.name, platform);
        },
      },
      {
        id: FeatureId.DISPLAY_NAME_MAX,
        label: 'Ellipsis Display Name',
        type: FeatureType.NUMBER,
        value: FeatureValue.DISPLAY_NAME_MAX,
        battle: true,
        getDefaultValue: (): number | null => {
          const { widget, attributes, platform } = this;

          switch (widget?.id) {
            case WidgetId.BUNDLE:
              return 60;
            case WidgetId.PEACOCK:
              return 18;
            case WidgetId.SHOP:
              return isMostPopularWidget(attributes?.name, platform) ? 18 : 35;
            case WidgetId.TILE:
            case WidgetId.VULTURE:
              return 250;
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.SERVERSIDE_IMAGES,
        type: FeatureType.BOOL,
        value: FeatureValue.SERVERSIDE_IMAGES,
        getDefaultValue: (): boolean => {
          const { widget } = this;

          return widget?.id === WidgetId.SHOP;
        },
      },
      {
        id: FeatureId.MODEL_OBJECTS,
        type: FeatureType.TEXT,
        value: FeatureValue.MODEL_OBJECTS,
      },
      {
        id: FeatureId.NAVIGATION,
        type: FeatureType.TEXT,
        value: FeatureValue.NAVIGATION,
      },
      {
        id: FeatureId.RELATED_PAGES,
        type: FeatureType.TEXT,
        value: FeatureValue.RELATED_PAGES,
      },
      {
        id: FeatureId.PARENT_PAGE,
        type: FeatureType.TEXT,
        value: FeatureValue.PARENT_PAGE,
      },
      {
        id: FeatureId.BREADCRUMBS,
        type: FeatureType.TEXT,
        value: FeatureValue.BREADCRUMBS,
      },
      {
        id: FeatureId.BUTTON_TYPE,
        label: 'Button Type',
        type: FeatureType.LIST,
        value: FeatureValue.BUTTON_TYPE,
        battle: true,
        options: [
          {
            value: '',
            formatted_value: 'Select Button Type',
          },
          {
            value: 'chevron',
            formatted_value: 'Chevron button',
          },
          {
            value: 'standard',
            formatted_value: 'Standard button',
          },
          {
            value: 'textbased',
            formatted_value: 'Merchant link text-based variant',
          },
        ],
        getDefaultValue: (): string => {
          const { widget, site } = this;

          switch (widget?.id) {
            case WidgetId.BUNDLE:
              return 'chevron';
            case WidgetId.MERCHANT_LINK:
              switch (site?.id) {
                case SiteId.ANDROID_CENTRAL:
                case SiteId.IMORE:
                case SiteId.WINDOWS_CENTRAL:
                case SiteId.MARIE_CLAIREUK:
                case SiteId.MARIE_CLAIREUS:
                case SiteId.WH:
                case SiteId.MOZO:
                case SiteId.SHORTLIST:
                  return '';
                default:
                  return 'textbased';
              }
            default:
              return '';
          }
        },
      },
      {
        id: FeatureId.ALL_DEALS_LINK,
        label: 'All deals link',
        type: FeatureType.TEXT,
        value: FeatureValue.ALL_DEALS_LINK,
      },
      {
        id: FeatureId.ALL_DEALS_LINK_TEXT,
        label: 'All deals link text',
        type: FeatureType.TEXT,
        value: FeatureValue.ALL_DEALS_LINK_TEXT,
      },
      {
        id: FeatureId.VISIBLE_MATCH_ID,
        label: 'Visible Match Id',
        type: FeatureType.TEXT,
        value: FeatureValue.VISIBLE_MATCH_ID,
      },
      {
        id: FeatureId.SEEN_MATCH_ID,
        label: 'Seen Match Id',
        type: FeatureType.TEXT,
        value: FeatureValue.SEEN_MATCH_ID,
      },
      {
        id: FeatureId.PAYWALL_ALLOWED,
        label: 'Paywall Allowed',
        type: FeatureType.BOOL,
        value: FeatureValue.PAYWALL_ALLOWED,
        battle: true,
        getDefaultValue: (): boolean => {
          const { site } = this;

          switch (site?.id) {
            case SiteId.CN:
              return true;
            default:
              return false;
          }
        },
      },
      {
        id: FeatureId.PRESERVE_EDITORIAL_LINK,
        label: 'Preserve Editorial Link',
        type: FeatureType.BOOL,
        value: FeatureValue.PRESERVE_EDITORIAL_LINK,
        battle: true,
        getDefaultValue: (): boolean => {
          const { widget, site, territory } = this;

          switch (site?.id) {
            case SiteId.MARIE_CLAIREUS:
            case SiteId.WH:
              if (widget?.id === WidgetId.MERCHANT_LINK && territory === 'US') {
                return true;
              }
              return false;
            default:
              return false;
          }
        },
      },
      {
        id: FeatureId.USEHAWK_PRODUCT_IMAGES,
        label: 'Use Hawk product images',
        type: FeatureType.BOOL,
        value: FeatureValue.USE_HAWK_PRODUCT_IMAGES,
        battle: true,
        getDefaultValue: (): boolean => false,
      },
      {
        id: FeatureId.SHOW_PRIMARY_LABEL,
        label: 'Primary Label',
        type: FeatureType.BOOL,
        value: FeatureValue.SHOW_PRIMARY_LABEL,
        battle: true,
        getDefaultValue: (): boolean => {
          const { site, attributes } = this;

          const dataName = attributes?.name;

          if (
            dataName !== 'Product Block merchantlink widget' &&
            (site?.id === SiteId.T3 || site?.id === SiteId.TRD || site?.id === SiteId.TG)
          ) {
            return true;
          }
          return false;
        },
      },
      {
        id: FeatureId.AVAILABLE_FROM_CLICKABLE,
        label: 'Available From Clickable',
        type: FeatureType.BOOL,
        value: FeatureValue.AVAILABLE_FROM_CLICKABLE,
        battle: true,
        getDefaultValue: (): boolean => false,
      },
      {
        id: FeatureId.EDITORIAL_WAS_PRICE,
        type: FeatureType.TEXT,
        value: FeatureValue.EDITORIAL_WAS_PRICE,
      },
      {
        id: FeatureId.EDITORIAL_PRICE,
        type: FeatureType.TEXT,
        value: FeatureValue.EDITORIAL_PRICE,
      },
      {
        id: FeatureId.EDITORIAL_CURRENCY,
        type: FeatureType.TEXT,
        value: FeatureValue.EDITORIAL_CURRENCY,
      },
      {
        id: FeatureId.MERCHANTLINK_TEXT_TYPE,
        label: 'Merchantlink Text type',
        type: FeatureType.LIST,
        value: FeatureValue.MERCHANTLINK_TEXT_TYPE,
        options: [
          {
            value: 'defaultMerchantLinkText',
            formatted_value: 'View at *',
          },
          {
            value: 'buyAtMerchantLinkText',
            formatted_value: 'Buy at *   ',
          },
          {
            value: 'shopAtMerchantLinkText',
            formatted_value: 'Shop at *',
          },
        ],
        battle: true,
        getDefaultValue: (): null => null,
      },
      {
        id: FeatureId.DEAL_WIDGET_COLLAPSIBLE_TEXT,
        label: 'Deal Widget Collapsible Text',
        type: FeatureType.BOOL,
        value: FeatureValue.DEAL_WIDGET_COLLAPSIBLE_TEXT,
        battle: true,
        getDefaultValue: (): boolean => {
          return false;
        },
      },
      {
        id: FeatureId.MODEL_BRAND,
        type: FeatureType.TEXT,
        value: FeatureValue.MODEL_BRAND,
      },
      {
        id: FeatureId.MODEL_PRODUCT_NAME,
        type: FeatureType.TEXT,
        value: FeatureValue.MODEL_PRODUCT_NAME,
      },
      {
        id: FeatureId.VERIFIED,
        label: 'Verified status visibility',
        type: FeatureType.LIST,
        value: FeatureValue.VERIFIED,
        options: [
          {
            value: 'exclusive',
            formatted_value: 'Verified status is present on exclusive offers ',
          },
          {
            value: 'codes',
            formatted_value: 'Verified status is present on code type offers',
          },
          {
            value: 'all',
            formatted_value: 'All offers can display verified status if applicable',
          },
        ],
        getDefaultValue: (): null => null,
        battle: true,
      },
      {
        id: FeatureId.MERCHANTLINK_STYLE,
        label: 'Merchantlink styling',
        type: FeatureType.LIST,
        value: FeatureValue.MERCHANTLINK_STYLE,
        options: [
          {
            value: 'box',
            formatted_value: 'Displays merchantlink as box (button)',
          },
          {
            value: 'hyperlink',
            formatted_value: 'Displays merchantlink as plain link',
          },
          {
            value: 'modernButton',
            formatted_value: 'Displays merchantlink as modern style button',
          },
        ],
        getDefaultValue: (): string => {
          switch (site?.id) {
            case SiteId.IDEAL_HOME:
            case SiteId.REAL_HOMES:
            case SiteId.LIVING_ETC:
            case SiteId.HOMES_AND_GARDENS:
            case SiteId.HOME_BUILDING:
            case SiteId.GARDENING_ETC:
              return 'box';
            default:
              return 'hyperlink';
          }
        },
        battle: true,
      },
    ];
  }
}
