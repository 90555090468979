import type { Variant } from '../../../types/Battle';
import type { Tab } from '../../../types/Tab';
import type { Widget } from '../../../types/Widget';
import { formatLeadingZero } from '../../countdownTiming';
import type Localiser from '../../Localiser';
import type { Site } from '../../sites/types/Site';
import type ApiFeaturesValues from '../types/ApiFeaturesValues';
import type Feature from '../types/Feature';
import FeatureType from '../types/FeatureType';
import type Option from '../types/Option';
import type WidgetFeaturesIds from '../types/WidgetFeaturesIds';

import getAttributeValue from './utils/getAttributeValue';
import getDefaultValue from './utils/getDefaultValue';
import getFeatureValue from './utils/getFeatureValue';
import hasValue from './utils/hasValue';

export enum SiteId {
  WHATHIFI = 'whathifi',
  ANDROID_CENTRAL = 'androidcentral',
  IMORE = 'imore',
  WINDOWS_CENTRAL = 'windowscentral',
  MARIE_CLAIREUS = 'marieclaireus',
  MARIE_CLAIREUK = 'marieclaireuk',
  WH = 'wh',
  MOZO = 'mozo',
  CN = 'cn',
  GOODTO = 'goodto',
  T3 = 't3',
  SHORTLIST = 'shortlist',
  WHO_WHAT_WEAR = 'whowhatwear',
  LIVING_ETC = 'lvg',
  MY_IMPERFECT_LIFE = 'myimperfectlife',
  TRD = 'trd',
  TG = 'tg',
  PCG = 'pcg',
  IDEAL_HOME = 'idealh',
  REAL_HOMES = 'realhomes',
  HOMES_AND_GARDENS = 'homesandgardens',
  HOME_BUILDING = 'homebuilding',
  GARDENING_ETC = 'gardeningetc',
}

export enum Platform {
  RESPONSIVE = 'responsive',
  PROGRESSIVE = 'progressive',
  MONA = 'mona',
  MOZO = 'mozo',
  PURCH = 'purch',
  SHORTLIST = 'shortlist',
}

export enum ArticleType {
  BUYING_GUIDE = 'buying_guide',
  REVIEW = 'review',
  AWARDS = 'awards',
}

export enum ArticleCategory {
  RETAIL = 'retail',
}

export enum TabCategory {
  MULTI_MEDIA = 'multimedia',
  SUBSCRIPTIONS = 'subscriptions',
}

/**
 * Array of features with the following properties:
 * id - name of the feature as it appears in an element dataset
 * label - the id for displaying in the battle UI
 * type - type of the value (text, number, bool etc.)
 * value - converted value of the feature (e.g. the API key)
 * options - available options for the feature. These are displayed in the Battle UI
 * battle - control if the feature should appear in the battle UI
 * getDefaultValue - function to get the default value (allows handling it differently than
 * other types)
 */
export default class {
  protected attributes: Record<string, string | undefined>;

  protected widget: Widget;

  protected editorial: boolean;

  protected platform: string;

  protected localiser: Localiser;

  protected site: Site;

  protected territory: string;

  protected battleFeatures: Variant;

  protected keywords: string[] | null;

  protected defaultTab: Partial<Tab> | null;

  public features: Feature[]; // public for getAllFeatures.ts

  constructor(
    attributes: Record<string, string | undefined>,
    widget: Widget,
    editorial: boolean,
    platform: string,
    localiser: Localiser,
    site: Site,
    territory: string,
    keywords: string[] | null,
  ) {
    this.attributes = attributes;
    this.widget = widget;
    this.editorial = editorial;
    this.platform = platform;
    this.localiser = localiser;
    this.site = site;
    this.territory = territory;
    this.battleFeatures = {} as Variant;
    this.keywords = keywords;
    this.defaultTab = {};
    this.features = [];
  }

  setAttribute(type: string, value: object | boolean | string | null): void {
    this[type] = value;
  }

  getListFeatureResult(options: { feature: Feature; values: string[] }): string {
    const { feature, values } = options;

    for (const value of values) {
      const option = this.getValidListFeatureOption(feature, value);

      if (option) {
        return option.value;
      }
    }
    return '';
  }

  getValidListFeatureOption(feature: Feature, value: string | number): Option | null {
    const values = value && String(value).indexOf(',') >= 0 ? String(value).split(',') : [value];
    const validOptions = (feature.options || []).filter((option) => {
      // The option is valid if any of the values match the option value - avoids needing to add
      // a separate entry for each combination of features
      return values.find((val) => {
        return option.value === val;
      });
    });

    if (validOptions && validOptions.length > 0) {
      return {
        ...validOptions[0],
        value: validOptions.map((o) => o.value).join(','),
        formatted_value: validOptions.map((o) => o.formatted_value).join(','),
      };
    }

    return null;
  }

  /**
   * Format the date and remove the seconds. This is to avoid creating new cache entries for
   * different seconds
   */
  getDateTimeValue(value: string): string {
    if (value && value !== 'now') {
      const date = new Date(value);
      date.setSeconds(0);
      return `${date.getFullYear()}-${formatLeadingZero(date.getMonth() + 1)}-${formatLeadingZero(
        date.getDate(),
      )} ${formatLeadingZero(date.getHours())}:${formatLeadingZero(
        date.getMinutes(),
      )}:${formatLeadingZero(date.getSeconds())}`;
    }
    return value;
  }

  getFeatures(key: 'value', discardEmptyValues?: boolean): Partial<ApiFeaturesValues>;

  getFeatures(key: 'id', discardEmptyValues?: boolean): Partial<WidgetFeaturesIds>;

  getFeatures(
    key: 'value' | 'id',
    discardEmptyValues = false,
  ): Partial<ApiFeaturesValues> | Partial<WidgetFeaturesIds> {
    const { attributes } = this;

    return this.features.reduce((result, feature) => {
      // Use the battle feature if it's specified, otherwise determine the
      // value based on the feature type
      if (this.battleFeatures && this.battleFeatures[feature?.id]) {
        const featureValue =
          this.battleFeatures[feature?.id] === '0'
            ? parseInt(this.battleFeatures[feature?.id], 10)
            : this.battleFeatures[feature?.id];

        if (discardEmptyValues && !hasValue(featureValue)) return result;
        result[feature[key]] = featureValue;
        return result;
      }
      switch (feature.type) {
        case FeatureType.TEXT: {
          const featureValue = <string>getFeatureValue(feature, attributes, '');

          if (discardEmptyValues && !hasValue(featureValue)) return result;
          result[feature[key]] = featureValue;
          return result;
        }
        case FeatureType.DATE_TIME: {
          const featureValue = <string>getFeatureValue(feature, attributes, '');
          const returnValue = featureValue !== '' ? this.getDateTimeValue(featureValue) : '';

          if (discardEmptyValues && !hasValue(returnValue)) return result;
          result[feature[key]] = returnValue;
          return result;
        }
        case FeatureType.LIST: {
          const attributeValue = getAttributeValue(feature, attributes) ?? '';
          const defaultValue = <string>getDefaultValue(feature);
          const featureValue = this.getListFeatureResult({
            feature,
            values: [attributeValue, defaultValue, ''],
          });

          if (discardEmptyValues && !hasValue(featureValue)) return result;
          result[feature[key]] = featureValue;
          return result;
        }
        case FeatureType.NUMBER: {
          const featureValue = <number | null>getFeatureValue(feature, attributes, null);

          if (discardEmptyValues && !hasValue(featureValue)) return result;
          result[feature[key]] = featureValue;
          return result;
        }
        case FeatureType.BOOL: {
          /* Boolean() to convert value from attributes to
          boolean( we can get any value from attributes) */
          const featureValue = Boolean(<boolean>getFeatureValue(feature, attributes, true));

          if (discardEmptyValues && !hasValue(featureValue)) return result;
          result[feature[key]] = featureValue;
          return result;
        }
        default: {
          const featureValue = null;

          if (discardEmptyValues && !hasValue(featureValue)) return result;
          result[feature[key]] = featureValue;
          return result;
        }
      }
    }, {});
  }
}
